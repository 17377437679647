import React, {Component} from 'react';
import { VelocityTransitionGroup } from 'velocity-react';
import 'rc-slider/assets/index.css';
import isDeepEqual from "lodash/isEqual";

import AnimationRange from './AnimationRange';
import TimeDisplay from './TimeDisplay';
import {ANIMATION_PAUSE_LIMIT} from '../../library/config';
import {ANIMATION_STATE} from '../../MapActions';

export const sliderFactor = 1; // granularity of slider: higher value means the slider values can reach higher, but frames must also be mapped to the slider values; ultimately this makes the slider animation look smoother with higher values
const tilesPerAnimationSequence = 13; // how many tiles to show for a layer within the whole slider range; Earthnetworks will give only 13 values, Aeris can potentially give more
export const animConfig = {
    min : 0, // min value of slider
    zero: tilesPerAnimationSequence * sliderFactor,
    max :  tilesPerAnimationSequence * sliderFactor, // max value of slider
    wait : 300 / sliderFactor, // wait time between each animation frame
    longWait : 1000 // wait time after full sequence before it restarts again
}

export default class Animation extends Component {

    constructor(props){

        super(props);

        // Setting frame date
        var date = new Date();
        var minutes = (Math.round(date.getMinutes() / 5) * 5);
        date.setMinutes(minutes);

        const nowSecs = Math.floor(new Date().getTime() / 1000);
        const animStartTimestamp = nowSecs + props.minTimeRange;
        const animEndTimestamp = nowSecs + props.maxTimeRange;
        const secondsPerStep = props.secsPerStep;

        // Setting slider values
        this.waitTime = animConfig.wait;
        this.state = {
            hover : false,
            startVal : props.minSliderVal,
            sliderVal : props.minSliderVal,
            endVal : props.maxSliderVal,
            frameDate : date,
            animStartTimestamp,
            animEndTimestamp,
            minSliderTimestamp: animStartTimestamp,
            maxSliderTimestamp: animEndTimestamp,
            secondsPerStep,
        };

    }

    componentDidUpdate(prevProps, prevState){
        if(!this.props.active && this.props.animState === ANIMATION_STATE.PLAYING){
            this.props.pauseAnimation();            
        }
        if(this.props.animState !== prevProps.animState){
            if(this.props.animState === ANIMATION_STATE.PLAYING){
                this.startAnimation();
                this.setTimeout('animationStop',  ANIMATION_PAUSE_LIMIT * 60 * 1000, () => {
                    this.props.pauseAnimation();
                });
            }
        }

        if (!isDeepEqual(this.props.items, prevProps.items) || 
          (this.props.minSliderVal !== prevProps.minSliderVal) ||
          (this.props.maxSliderVal !== prevProps.maxSliderVal) ||
          (this.props.minTimeRange !== prevProps.minTimeRange) ||
          (this.props.maxTimeRange !== prevProps.maxTimeRange) ||
          (this.props.secsPerStep !== prevProps.secsPerStep)
        ){
          const secondsPerStep = this.props.secsPerStep;
          const nowSecs = Math.floor(new Date().getTime() / 1000);

          const minSliderTimestamp = nowSecs + this.props.minTimeRange;
          const maxSliderTimestamp = nowSecs + this.props.maxTimeRange;
          const animStartTimestamp = minSliderTimestamp + secondsPerStep * this.props.minSliderVal;
          const animEndTimestamp = minSliderTimestamp + secondsPerStep * this.props.maxSliderVal;
          
          // console.log("secondsPerStep", secondsPerStep);
          // console.log("minSliderTimestamp"  , minSliderTimestamp, new Date(minSliderTimestamp*1000).toISOString())
          // console.log("maxSliderTimestamp"  , maxSliderTimestamp, new Date(maxSliderTimestamp*1000).toISOString())
          // console.log("animStartTimestamp"  , animStartTimestamp, new Date(animStartTimestamp*1000).toISOString())
          // console.log("animEndTimestamp"    , animEndTimestamp  , new Date(animEndTimestamp *1000) .toISOString())

          this.setState({
              startVal: this.props.minSliderVal,
              endVal: this.props.maxSliderVal,
              sliderVal: this.props.interval,
              minTimeRange: this.props.minTimeRange,
              maxTimeRange: this.props.maxTimeRange,
              animStartTimestamp,
              animEndTimestamp,
              minSliderTimestamp,
              maxSliderTimestamp,
              secondsPerStep,
              frameDate: new Date((minSliderTimestamp + secondsPerStep * this.props.interval) * 1000),
          })

        }

    }

    componentWillUnmount(){

        this.cleanTimeout('animation')
        this.cleanTimeout('animationStop');

        if(this.props.animState === ANIMATION_STATE.PLAYING){
            this.props.pauseAnimation()
        }

    }


    onSliderChange(val){
        // console.log("onSliderChange", val);
        const animStartTimestamp = this.state.minSliderTimestamp + this.state.secondsPerStep * val[0];
        const animEndTimestamp = this.state.minSliderTimestamp + this.state.secondsPerStep * val[2];
        const currSliderVal = val[1];
        this.setState({
            startVal : val[0],
            sliderVal : currSliderVal,
            endVal : val[2],
            animStartTimestamp,
            animEndTimestamp,
        })

        this.changeIntervalIfNecessary(currSliderVal);
        this.changeFrameTimeIfNecessary(currSliderVal);
    }


    setTimeout(ref, time, callback){

        this.cleanTimeout(ref);

        this[ref] = setTimeout(() => callback(), time); //minute to ms
    }

    cleanTimeout(ref){

        if (this[ref]) {
            clearTimeout(this[ref])
            ref = null
        }
    }

    onAnimationButtonClick(){

        if(this.props.animState === ANIMATION_STATE.PAUSED){

            this.props.startAnimationLoad();

        } else if(this.props.animState === ANIMATION_STATE.PLAYING){

            this.cleanTimeout('animationStop');
            this.props.pauseAnimation();
        }
    }

    startAnimation(){
        this.setTimeout('animation', this.waitTime, (() => {
            let nextVal = this.state.sliderVal + 1;

            if(nextVal === this.state.endVal){
                this.waitTime = animConfig.longWait;
            } else if(nextVal > this.state.endVal) {
                nextVal = this.state.startVal;
                this.waitTime = animConfig.wait;
            } else {
                this.waitTime = animConfig.wait;
            }

            this.setState({sliderVal: nextVal});

            if(this.props.animState === ANIMATION_STATE.PLAYING){
                this.startAnimation();
            }

            // Changing interval
            this.changeIntervalIfNecessary(nextVal);

            // Setting time for current frame
            this.changeFrameTimeIfNecessary(nextVal);
        }))
    }

    getCurrSecsForSliderVal = (currInterval) => {
        const currSecsForSliderVal = this.state.minSliderTimestamp + this.state.secondsPerStep * currInterval;
        var date = new Date(currSecsForSliderVal * 1000);
        return {currSecsForSliderVal, date};
    }

    changeIntervalIfNecessary(currInterval){
        if(this.props.interval !== currInterval){
            const {currSecsForSliderVal} = this.getCurrSecsForSliderVal(currInterval);
            this.props.changeAnimationInterval(currInterval, currSecsForSliderVal);
        }
    }

    changeFrameTimeIfNecessary(currInterval){
        const {date} = this.getCurrSecsForSliderVal(currInterval);
        this.setState({frameDate : date});
    }

    renderButtonIcon(){

        var iconClass = 'control-panel-button-icon control-panel-animation-button-icon fa ';
        switch(this.props.animState){

            case ANIMATION_STATE.PAUSED:
                return (
                    <img  src={require(`images/map/map-animation-play${this.state.hover ? '-hover' : ''}.svg`)} alt="play"/>
                )

            case ANIMATION_STATE.LOADING :
                return (
                    <span className={iconClass + ' fa-spinner fa-pulse fa-fw'} ></span>
                )

            case ANIMATION_STATE.PLAYING :
                return (
                    <img  src={require(`images/map/map-animation-pause${this.state.hover ? '-hover' : ''}.svg`)} alt="pause"/>
                )

            default:
                break;
        }

    }

    renderSlider(){
        return (
            <div className='control-panel-animation-slider-component'>
                <AnimationRange
                    min={this.props.minSliderVal}
                    max={this.props.maxSliderVal}
                    value={[this.state.startVal, this.state.sliderVal, this.state.endVal]}
                    onChange={this.onSliderChange.bind(this)}
                    pushable={true}
                />
            </div>
        )
    }

    renderAnimationBottom(){

        const animStartDate = this.state.animStartTimestamp * 1000;
        const animEndDate = this.state.animEndTimestamp * 1000;

        const getDateStr = (date) => {

            date = new Date(date);
            const hours =  ('0' + date.getHours().toString()).slice(-2);
            const mins =  ('0' + date.getMinutes().toString()).slice(-2);

            return hours + ':' + mins;
        }

        if(!animStartDate || !animEndDate){
            return null;
        }

        const startStr = getDateStr(animStartDate);
        const endStr = getDateStr(animEndDate);
        
        return (
            <div className='control-panel-animation-bottom'>
                <div className='control-panel-animation-bottom-left'>
                    {startStr}
                </div>
                <div className={`control-panel-animation-bottom-right`}>
                    {endStr}
                </div>
            </div>
        )
    }
    
    render(){

        return (
            <div className={'control-panel-item' + (this.props.expanded ? ' item-expanded' : '')}>
                <TimeDisplay
                    expanded = {this.props.expanded}
                    date={this.state.frameDate}
                />
                <div className='control-panel-animation'>
                    <button
                        className='control-panel-button control-panel-animation-button'
                        onClick={this.onAnimationButtonClick.bind(this)}
                        onMouseOver={() => {this.setState({hover : true})}}
                        onMouseOut={() => {this.setState({hover : false})}}
                    >
                        {this.renderButtonIcon()}
                    </button>
                    <VelocityTransitionGroup
                        enter={{
                            animation: 'fadeIn',
                            duration:300
                        }}
                        leave={{
                            animation: 'fadeOut',
                            duration : 100
                        }}
                    >
                        {this.props.expanded ? this.renderSlider() : null}
                    </VelocityTransitionGroup>
                </div>
                {this.props.expanded ? this.renderAnimationBottom.bind(this)() : null}
            </div>
        )
    }
}
