// to not load Aeris map data (e.g. to save network bandwidth, set REACT_APP_NO_AERIS_MAP=true in either .env or .env.local in the root directory
export const AERIS_CLIENT_ID = process.env.REACT_APP_NO_AERIS_MAP_CALLS === 'true' ? '' : 'icgy89ko1slCRZaQdS4yW';
export const AERIS_CLIENT_PASSWORD = process.env.REACT_APP_NO_AERIS_MAP_CALLS === 'true' ? '' : 'ltVnjmfYHFXGq1drbweIQhJ3aWf1INOW11nVFOAI';

let enApiMetadataUrl = process.env.REACT_APP_EARTHNETWORKS_API_METADATA_URL;
let enApiTileUrl = process.env.REACT_APP_EARTHNETWORKS_API_TILE_URL;
let apiToUse = process.env.REACT_APP_EARTHNETWORKS_API_METHOD ?? "v3";
const enApiKey = process.env.REACT_APP_EARTHNETWORKS_API_KEY;
if(apiToUse === 'direct') {
  // direct call for local testing needs API key
  enApiMetadataUrl = enApiMetadataUrl + `&subscription-key=${enApiKey}`;
  enApiTileUrl = enApiTileUrl + `&subscription-key=${enApiKey}`;
}
export const EARTHNETWORKS_API_TO_USE = apiToUse;
export const EARTHNETWORKS_API_METADATA_URL = enApiMetadataUrl;
export const EARTHNETWORKS_API_TILE_URL = enApiTileUrl;
// export const EARTHNETWORKS_API_KEY = enApiKey; // TODO: don't use this in production but use proxy url

export const earthNetworksMapLayerNames = {
  v3: {
    "radar": "radar",
    "forecastRadar": "radar-forecast",
    "satelliteVisible": "satellite",
    "satelliteInfraredColor": "satellite-infrared",
    "winterAdvisories": "nws-alerts", // &winter=true
    "nonwinterAdvisories": "nws-alerts", // &winter=false
    "winterAdvisory": "nws-alerts", // &winter=true
    "nonwinterAdvisory": "nws-alerts", // &winter=false
    "winterAndNonwinterAdvisory": "nws-alerts",
  },
  direct: {
    "radar": "Radar.NA.LoAlt",
    "forecastRadar": "Radar.Baron.Forecast",
    "satelliteVisible": "vis-sat",
    "satelliteInfraredColor": "irsat",
    "winterAdvisories": "nws-alerts",
    "nonwinterAdvisories": "nws-alerts", // &winter=false
    "winterAdvisory": "nws-alerts", // &winter=true
    "nonwinterAdvisory": "nws-alerts", // &winter=false
    "winterAndNonwinterAdvisory": "nws-alerts",
  }
};
export const getEarthNetworksMapLayerName = genericLayerId => earthNetworksMapLayerNames[apiToUse][genericLayerId];


// ask Aeris for frames by absolute time stamp. 
export const calculateAerisFrameTimes = () => {
  const hours = 2;
  let ts = Math.floor(new Date().getTime() / 1000) - hours * 60 * 60; // "x hour in the past" in seconds
  const times = [];
  for(let i = 0; i < 13 * hours; i++){ // fetch 13 values from Aeris
    times.push({
      id: i,
      time: ts
    });
    ts += 5 * 60; // go 5 minutes in the past
  }
  return times;
}

export const calculateAerisForecastFrameTimes = () => {
  let ts = Math.floor(new Date().getTime() / 1000);
  const times = [];
  for(let i = 0; i < 13; i++){ // fetch 13 values from Aeris
    times.push({
      id: i,
      time: ts
    });
    ts += 10 * 60; // go 10 minutes in the future
  }
  return times;
}

// Earthnetworks other layers times (in seconds)
export const earthNetworksFrameTimes = (metadata) => {
  return metadata?.animationSchedules?.map((slot, i) => ({
    id: i,
    time: slot
  }));
}

export const ANIMATION_PAUSE_LIMIT = 1; //minutes
