import React from 'react';

function getGetOrdinal(n) {

    var s=["th","st","nd","rd"],
    v=n%100;
    return n+(s[(v-20)%10]||s[v]||s[0]);
 }

function getDateLong(date){

    const month = (date.toLocaleString( "en-us", { month: "long" })).substr(0,3);
    const day = getGetOrdinal(date.getDate());
    const hour =  ('0' + date.getHours().toString()).slice(-2);
    const min =  ('0' + date.getMinutes().toString()).slice(-2);

    return month + " " +day + ", " + hour + ":" + min;
}

function getDateShort(date){

    const dayAndMonth = (date.getMonth() + 1).toString() + "/" + date.getDate();
    const hour =  ('0' + date.getHours().toString()).slice(-2);
    const min =  ('0' + date.getMinutes().toString()).slice(-2);
    return [
        dayAndMonth,
        (hour + ":" + min)
    ]
}

export default function TimeDisplay(props){

    var date = props.date;

    if(props.expanded){

        const dateStr = getDateLong(date);
        
        // margin:30px if both
        return (
            <div className={`control-panel-time-display`}>
                {dateStr}
            </div>
        )
    } else {

        const dateShort = getDateShort(date);
        return (
            <div className='control-panel-time-display'>
                {dateShort[0]}
                <br/>
                {dateShort[1]}
            </div>
        )
    }
}


