import axios from 'axios'
import { put, takeEvery, call, select, /*spawn, cancel, take, race*/ } from 'redux-saga/effects'
import fetchApi from 'utils/fetchApi';

import {ACTIONS} from './MapActions';
import {AERIS_CLIENT_ID, AERIS_CLIENT_PASSWORD, getEarthNetworksMapLayerName, EARTHNETWORKS_API_METADATA_URL, EARTHNETWORKS_API_TO_USE} from './library/config';
// import { LOGOUT_SUBMIT, LOGIN_SUCCESS } from 'Login/LoginDuck';
// import { DATA_SUCCESS } from 'common/dataRetrieval/DataDuck';
import { animConfig } from 'Map/ControlPanel/Animation/AnimationComponent.js';

const API_URL = 'https://api.aerisapi.com';

// const POLL_INTERVAL_SECONDS = 300;

function fetchLocationWeatherInfo(latLng) {

    const requestUrl =
    `${API_URL}/batch?p=${latLng?.lat},${latLng?.lng}` +
    `&filter=&query=&client_id=${AERIS_CLIENT_ID}&client_secret=${AERIS_CLIENT_PASSWORD}` +
    '&requests=' +
    '%2Fforecasts%253Ffilter%3D1hr%2526limit%3D7%26query%3D' +
    '%2C%2Fforecasts%253Ffilter%3Dday%2526limit%3D5%26query%3D%2C' +
    '%2Fobservations%253Ffilter%3Dmesonet%2526query%3Dtemp%3A-999%2C' +
    '%2Fadvisories%253Ffields%3Ddetails.name%2Cdetails.body%2Ctimestamps%2526filter%3D%26query%3D%2C' +
    '%2Fplaces%253Ffilter%3D%2526query%3D';

    return axios.get(requestUrl);
};


export function* findLocation(action) {

  try {
    const response = yield call(fetchLocationWeatherInfo, action.payload.location);

    // Instructing middleware to dispatch corresponding action.
    yield put({
        type: ACTIONS.FETCH_LOCATION_WEATHER_SUCCESS,
        payload : {
            location : action.payload.location,
            response : response,
            showPanel: action.payload.showPanel
        }

    });
  } catch (err) {
    console.log(err);
  }

}


export function* tileLoaded(action) {
    
    const authToken = yield select((state) => state.login.authToken);
    const url = `/Persons/tileCount`
    /*const subscriptions =*/ yield call(fetchApi, url, {
        authToken,
        payload : {
            count : action.payload.count
        },
        method: 'POST'
    })
    
}

export function* fetchEarthnetworksMetadata(action) {
  try {
    if(!EARTHNETWORKS_API_METADATA_URL) {
      console.error("WARNING: REACT_APP_EARTHNETWORKS_API_METADATA_URL undefined in env");
      return;
    }

    let apiToUse = EARTHNETWORKS_API_TO_USE;
    let layerId = action.payload.layerId;
    let layerForUrl = getEarthNetworksMapLayerName(layerId);
    let layerUrl;

    let headers = {};
    if(apiToUse === 'direct') {
      // direct call for local testing needs API key
      layerUrl = EARTHNETWORKS_API_METADATA_URL.replace('lid', `lid=${layerForUrl}`);
    } else {
      layerUrl = EARTHNETWORKS_API_METADATA_URL.replace('layer', `layer=${layerForUrl}`);
      headers = {
          'Authorization': process.env.REACT_APP_EARTHNETWORKS_V3_API_KEY,
      }
    }
    const axiosConfig = {
      headers
    };

    const metadata = yield call(axios.get, layerUrl, axiosConfig);

    let animationSchedules, latestSlot, intervalSeconds;
    if(apiToUse === 'direct') {
      animationSchedules = metadata?.data?.Result?.AnimationSchedules?.[0]?.Slots?.sort();
      latestSlot = metadata?.data?.Result?.LatestSlot;
      intervalSeconds = metadata?.data?.Result?.AnimationSchedules?.[0]?.IntervalSeconds;
    } else {
      animationSchedules = metadata?.data?.data?.schedules?.[0]?.slots?.sort();
      latestSlot = animationSchedules?.[animationSchedules.length-1];
      intervalSeconds = metadata?.data?.data?.schedules?.[0]?.interval;
    }

    // console.log("EarthNetworks ", layerId, "metadata:", metadata);
    // console.log("LatestSlot:", latestSlot);
    // console.log("AnimationSchedules:", animationSchedules);

    yield put({
      type: ACTIONS.SET_EARTHNETWORKS_MAP_METADATA,
      payload : {
        layerId,
        values: {
          latestSlot,
          animationSchedules,
          intervalSeconds,
        }
      }
    });

    yield put({
      type: ACTIONS.SET_EARTHNETWORKS_MAP_METADATA_LOADED,
      payload : {
        layerId,
        dataLoaded: true,
      }
    });

    yield put({
      type: ACTIONS.UPDATE_ANIMATION_SLIDER_RANGES
    });

  } catch(err) {
    console.log("fetchEarthnetworksMetadata ERROR", err);
  }
}

// const delay = millis =>
//   new Promise(resolve => {
//     setTimeout(() => resolve(true), millis);
//   });

// TODO: should the Earthnetworks metadata and time slots be updated regularly, this function can do this; however to work fully, the old map tiles must be updated as well to include the new slots
// function* refreshEarthnetworksMapMetadata() {
//   while (true) {
//     const items = yield select((state) => state.mapControls.items);
//     const selectedItems = getSelectedItems(items);
//     for(const layerId of selectedItems) {
//       yield call(fetchEarthnetworksMetadata, {payload:{layerId}});
//     }
//     yield call(delay, POLL_INTERVAL_SECONDS * 1000);
//   }
// }

/**
 * The slider values can go from 0..max where 0..frameZero are for past up to now and future are frameZero..max. Layer/item types for Aeris and Earthnetworks have 13 values each
 * Accomodate cases where there are only past, past+future or only future layers.
*/
const calculateSliderMinMaxValues = (items = []) => {
  // min/max ranges initially
  let pastHours = 0;
  let futureHours = 0;
  for(const item of items) {
     switch(item) {
       case 'radar': // earthnetworks
         pastHours = Math.max(pastHours, 1);
         break;
       case 'satelliteVisible': // Earthnetworks
       case 'satelliteInfraredColor': // Earthnetworks
         pastHours = Math.max(pastHours, 3);
         break;
       case 'forecastRadar': // Earthnetworks
       case 'forecastWinds': // Aeris
       case 'forecastWindGusts': // Aeris
         futureHours = Math.max(futureHours, 2);
         break;
       case 'winterAdvisories':
       case 'nonwinterAdvisories':
         pastHours = Math.max(pastHours, 0.25);
         break;
       case 'threats': 
         // this layer doesn't have a time domain
         break;
       default: // other Aeris layers
         pastHours = Math.max(pastHours, 2);
     }
  }
  const pastSeconds = pastHours * 60 * 60;
  const futureSeconds = futureHours * 60 * 60;
  const totalSeconds = pastSeconds + futureSeconds;
  const maxSliderValue = animConfig.max * (pastHours + futureHours);
  const secsPerStep = maxSliderValue !== 0 ? totalSeconds / maxSliderValue : 0;
  // at which frame ends past and begins future:
  const frameZero = totalSeconds !== 0 ? (pastSeconds / totalSeconds) * maxSliderValue : 0;

  let min = maxSliderValue;
  let max = animConfig.min;
  let noValuesCalculated = true;
  for(const item of items) {
     switch(item) {
       case 'radar': // earthnetworks
       case 'satelliteVisible': // Earthnetworks
       case 'satelliteInfraredColor': // Earthnetworks
       case 'forecastRadar': // Earthnetworks
       case 'forecastWinds': // Aeris
       case 'forecastWindGusts': // Aeris
         min = Math.min(min, animConfig.min);
         max = Math.max(max, maxSliderValue);
         noValuesCalculated = false;
         break;
       case 'threats': 
         // this layer doesn't have a time domain
         break;
       default: // other Aeris layers
         min = Math.min(min, animConfig.min);
         max = Math.max(max, maxSliderValue);
         noValuesCalculated = false;
     }
  }
  // console.log("%ccalculateSliderMinMaxValues minSliderVal=", "color:green", min, max, "secsPerStep=", secsPerStep, "frameZero=", frameZero);
  if(noValuesCalculated) {
    return {minSliderVal: animConfig.min, maxSliderVal: animConfig.min, secsPerStep: 0, frameZero: 0};
  } else {
    return {minSliderVal: min, maxSliderVal: max, secsPerStep, frameZero };
  }
}

/**
 * Filters items/layers for only selected (visible) ones and only those that have a time domain.
 * helper for updateAnimationSliderRanges
*/
const getSelectedItems = (items = {}) => {
  return Object.keys(items)
    .filter(key => !['threats'].includes(key))
    .reduce((acc, key) => {
      if(items[key].selected) acc.push(items[key].layer); 
      return acc;
    }, []);
}

// helper for updateAnimationSliderRanges: calculate seconds in past/future depending on which layers/items are selected/visible
const getMaxTimeRangeForItems = (items = {}) => {
  let min = 0;
  let max = 0;
  for(const item of items) {
     switch(item) {
       case 'radar': // earthnetworks has 1 hours worth of data
         min = Math.min(-60, min);
         break;
       case 'satelliteVisible': // Earthnetworks has 3 hours worth of data
       case 'satelliteInfraredColor': // Earthnetworks has 3 hours worth of data
         min = Math.min(-60 * 3, min);
         break;
       case 'forecastRadar': // Earthnetworks has 2 hours worth of forecast data
       case 'forecastWinds': // Aeris
       case 'forecastWindGusts': // Aeris
         max = Math.max(0, 60 * 2);
         break;
       case 'winterAdvisories':
       case 'nonwinterAdvisories':
         min = Math.min(-15, min);
         break;
       default: // Aeris
         min = Math.min(-120, min);
         break;
     }
  }
  return {minTimeRange: min * 60, maxTimeRange: max * 60};
}

function* updateAnimationSliderRanges(payload) {
  try {
    const items = yield select((state) => state.mapControls.items);
    const selectedItems = getSelectedItems(items);
    const {minSliderVal, maxSliderVal, secsPerStep, frameZero} = calculateSliderMinMaxValues(selectedItems);
    const {minTimeRange, maxTimeRange} = getMaxTimeRangeForItems(selectedItems);
    // minSliderVal and minTimeRange correspond to each other (also max... values)
    yield put({
      type: ACTIONS.ANIMATION_SLIDER_CHANGED,
      payload : {
        minSliderVal, maxSliderVal, minTimeRange, maxTimeRange, secsPerStep, frameZero
      }
    });
  } catch (err) {
    console.error("updateAnimationSliderValues error:", err);
  }
}


export function* watchMap(){
    yield takeEvery(ACTIONS.FETCH_LOCATION_WEATHER, findLocation)
    yield takeEvery(ACTIONS.TILE_LOADED, tileLoaded)
    yield takeEvery(ACTIONS.FETCH_EARTHNETWORKS_MAP_METADATA, fetchEarthnetworksMetadata)
    yield takeEvery(ACTIONS.ITEM_SELECTED, updateAnimationSliderRanges)
    yield takeEvery(ACTIONS.UPDATE_ANIMATION_SLIDER_RANGES, updateAnimationSliderRanges)

    // TODO: to start an updater cycle for Earthnetworks map metadata
    // while(true) {
    //   yield race([take(LOGIN_SUCCESS), take(DATA_SUCCESS)]);
    //   console.log('Starting new Earthnetworks map metadata fetching task')
    //   const earthNetworksMapMetadataTask = yield spawn(refreshEarthnetworksMapMetadata);
    //   yield take(LOGOUT_SUBMIT);
    //   console.log('Stopping Earthnetworks map metadata fetching task')
    //   yield cancel(earthNetworksMapMetadataTask);
    // }
}
