import Slider from 'rc-slider';

export default class AnimationRange extends Slider.Range {

    getClosestBound(value) {

        const { bounds } = this.state;
        if(value <= bounds[0] || value - bounds[0] < 3){
            return 0;
        } else if(value >= bounds[2] || bounds[2] - value < 3){
            return 2;
        } else {
            return 1;
        }

     }
}
